@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700|Open+Sans&display=swap);
/** @format */
/* @import "~react-image-gallery/styles/css/image-gallery.css"; */

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: "Helvetica", sans-serif;
}

body {
	/* background-color: #ededed; */
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: "Helvetica", sans-serif;
}

:root {
	--primaryColor: #5fb7ea;
	--primaryRGBA: rgba(0, 0, 0, 0.3);
	--mainGrey: #fafafa;
	--darkGrey: #5f5e5e;
	--mainWhite: #fff;
	--mainBlack: #222;
	--mainTransition: all 0.3s ease-in-out;
	--mainSpacing: 0.3rem;
	--clr-primary-1: hsl(22, 28%, 21%);
	--clr-primary-2: hsl(22, 28%, 29%);
	--clr-primary-3: hsl(22, 28%, 37%);
	--clr-primary-4: hsl(22, 28%, 45%);
	/* primary/main color */
	--clr-primary-5: hsl(22, 31%, 52%);
	/* lighter shades of primary color */
	--clr-primary-6: hsl(22, 31%, 60%);
	--clr-primary-7: hsl(22, 31%, 67%);
	--clr-primary-8: hsl(20, 31%, 74%);
	--clr-primary-9: hsl(22, 31%, 81%);
	--clr-primary-10: hsl(22, 31%, 88%);
	/* darkest grey - used for headings */
	--clr-grey-1: hsl(209, 61%, 16%);
	--clr-grey-2: hsl(211, 39%, 23%);
	--clr-grey-3: hsl(209, 34%, 30%);
	--clr-grey-4: hsl(209, 28%, 39%);
	/* grey used for paragraphs */
	--clr-grey-5: hsl(210, 22%, 49%);
	--clr-grey-6: hsl(209, 23%, 60%);
	--clr-grey-7: hsl(211, 27%, 70%);
	--clr-grey-8: hsl(210, 31%, 80%);
	--clr-grey-9: hsl(212, 33%, 89%);
	--clr-grey-10: hsl(210, 36%, 96%);
	--clr-white: #fff;
	--clr-red-dark: hsl(360, 67%, 44%);
	--clr-red-light: hsl(360, 71%, 66%);
	--clr-green-dark: hsl(125, 67%, 44%);
	--clr-green-light: hsl(125, 71%, 66%);
	--clr-black: #222;
}

.sidebaricon {
	font-size: 1.35rem;
	cursor: pointer;
	margin-left: 4px;
	margin-top: 4px;
}

@media (min-width: 751px) {
	.overAllSidebar {
		display: none;
	}
	.sidebaricon {
		display: none;
	}
}

@media (max-width: 900px) {
	.loginGoogle {
		font-size: 13px !important;
	}
}

.DarkbackgroundForSidebar {
	background-color: rgba(0, 0, 0, 0.3);
	height: 100%;
	top: 50px;
	left: 0;
	position: fixed;
	z-index: 100;
	padding: 1000px;
}

.DarkbackgroundForSidebar2 {
	background-color: rgba(0, 0, 0, 0.3);
	height: 100%;
	top: 60px;
	left: 0;
	position: fixed;
	z-index: 100;
	padding: 1000px;
}

.detailsAboutAppointment {
	font-weight: bold;
	font-size: 0.95rem;
	/* text-shadow: 1px 1px 4px; */
	color: #7a0909;
}

